<template>
  <div class="container-fluid main-container__librery">
    <div v-if="getClass" class="float-button pr-5">
      <a :href="`/admin/clases/clase/preview/${getClass.id}`" target="_blank">
        <i class="fas fa-eye eye-course"></i>
      </a>
    </div>
    <div class="container-styles p-3">
      <vue-tabs>
        <v-tab title="Configuración">
          <loading-panel
            v-if="isLoading"
            class="loading-course-detail"
            title="Cargando detalles de la clase"
          ></loading-panel>
          <div v-else class="container">
            <div class="form-group row">
              <div class="course-image-container col-md-6 mx-auto">
                <img
                  v-if="formData.poster && formData.poster.url"
                  :src="formData.poster.url"
                  alt="Thumbnail"
                  class="course-image"
                  width="570"
                  height="500"
                />
                <img
                  v-else
                  src="@/assets/images/logo/placeholder.png"
                  alt="Thumbnail"
                  class="course-image"
                  width="570"
                  height="500"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">Imagen de portada</label>
                <file-input @change="changePoster"></file-input>
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-md-12">
                <label class="style-text-config">Nombre de la clase</label>
                <base-input
                  v-model="formData.title"
                  class="name-course-input"
                  placeholder="Titulo de la lección"
                ></base-input>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">Descripción</label>
                <quill-editor
                  ref="myQuillEditor"
                  v-model="formData.content"
                  :options="editorOption"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">Asignar Video</label>
                <multiselect
                  v-model="formData.video"
                  :allow-empty="false"
                  :loading="isLoadingVideo"
                  :option-height="104"
                  :options="this.getVideos"
                  :show-labels="true"
                  class="modify-input"
                  label="title"
                  placeholder="Seleccione video de la galeria"
                  track-by="id"
                  @search-change="searchTrailer"
                >
                  <template #options="props">
                    <img
                      :src="props.option.thumbnail"
                      alt="Video"
                      class="option__image"
                    />
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.title ? props.option.title : ''
                      }}</span>
                      <span class="option__small">{{
                        props.option.content ? props.option.content : ''
                      }}</span>
                    </div>
                  </template>
                  <template #noResult>
                    <div>Sin resultados</div>
                  </template>
                  <template #noOptions>
                    <div>Sin resultados</div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">ASIGNAR PRODUCTOS</label>
                <multiselect
                  v-model="formData.products"
                  :loading="isLoadingProducts"
                  :multiple="true"
                  :options="this.getProducts.items"
                  :taggable="true"
                  class="modify-input"
                  label="title"
                  placeholder="Elige los productos a asociar."
                  tag-placeholder="Add this as new tag"
                  track-by="id"
                  @search-change="searchProduct"
                >
                  <template #noOptions>La lista esta vacía</template>
                  <template #noResult>
                    <div>Sin resultados</div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">Instructor</label>

                <multiselect
                  v-model="formData.instructors"
                  :loading="isLoadingInstructors"
                  :multiple="true"
                  :option-height="104"
                  :options="this.getInstructors.items"
                  :show-labels="true"
                  :taggable="true"
                  class="border-0 select-config"
                  label="firstname"
                  placeholder="Instructores"
                  track-by="id"
                  @search-change="searchInstructor"
                >
                  <template #option="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{
                          props.option.firstname + ' ' + props.option.lastname
                        }}
                      </span>
                      <span
                        v-if="
                          props.values &&
                          props.values.length &&
                          !props.values.isOpen
                        "
                        class="multiselect__single"
                        >{{ props.values.length }}</span
                      >
                    </div>
                  </template>
                  <template #noResult>
                    <div>Sin resultados</div>
                  </template>
                  <template #noOptions>
                    <div>Sin resultados</div>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <label class="style-text-config">Categoria</label>

                <multiselect
                  v-model="formData.categories"
                  :loading="isLoadingCategories"
                  :multiple="true"
                  :option-height="104"
                  :options="this.getCategories.items"
                  :show-labels="true"
                  :taggable="true"
                  class="border-0 select-config"
                  label="title"
                  placeholder="Categorías"
                  track-by="id"
                >
                  <template #option="props">
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.title
                      }}</span>
                      <span
                        v-if="
                          props.values &&
                          props.values.length &&
                          !props.values.isOpen
                        "
                        class="multiselect__single"
                        >{{ props.values.length }}</span
                      >
                    </div>
                  </template>
                  <template #noResult>
                    <div>Sin resultados</div>
                  </template>
                  <template #noOptions>
                    <div>Sin resultados</div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label form-control-label"
                >ARCHIVOS</label
              >
              <div class="col-md-9">
                <vue-dropzone
                  id="file-dropzone"
                  ref="filedropzone"
                  :options="classFileOptions"
                  @vdropzone-success="successUploadClassFile"
                ></vue-dropzone>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label form-control-label"
                >LISTA DE ARCHIVOS</label
              >

              <div v-if="isLoadingClassFiles" class="col-md-9">
                <skeleton-course-video-file />
              </div>
              <div v-else class="col-md-9 pt-2">
                <div v-if="getClassFiles.length">
                  <ul class="file-list">
                    <li v-for="classFile in getClassFiles" :key="classFile.id">
                      <i
                        class="fas fa-times delete-button mx-3"
                        @click="deleteClassFile(classFile.id)"
                      ></i>

                      <a
                        :href="classFile.url"
                        class="file-list__linkcontainer"
                        style="color: #b7227e"
                        target="_blank"
                      >
                        <i class="fas fa-file-archive"></i>
                        {{ classFile.file_name }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div v-else class="dark-font">
                  No tiene archivos asignados a este video
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <select v-model="formData.status" class="select-status">
                  <option
                    v-for="status in states"
                    :key="status.status"
                    :value="status.status"
                  >
                    {{ status.name }}
                  </option>
                </select>
                <i class="fas fa-chevron-down details-select"></i>
              </div>
            </div>

            <base-button
              :loading="isUpdateClass"
              class="primary btn-maquillate py-2 px-3"
              @click="updateClass"
              >Guardar cambios
            </base-button>
          </div>
        </v-tab>
        <v-tab title="SEO">
          <seo-view
            :seo-data="seoClass"
            :is-loading="isSeoLoading"
            @create-seo="createSeo"
            @update-seo="updateSeo"
          />
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import config from '@/config/config';
import LoadingPanel from '../../../../components/LoadingPanel.vue';
import Multiselect from 'vue-multiselect';
import {
  DELETE_CLASS_FILE,
  FETCH_CLASS,
  FETCH_CLASS_FILES,
  UPDATE_CLASS,
  UPDATE_CLASS_POSTER,
} from '../../../../store/actions/classes';
import { mapGetters } from 'vuex';
import { CLASS_DEFAULT_VALUES } from '../../../../util/constants';
import { FETCH_INSTRUCTORS } from '../../../../store/actions/instructor';
import { FETCH_CATEGORIES } from '../../../../store/actions/categories';
import { quillEditor } from 'vue-quill-editor';
import { FETCH_PRODUCTS } from '../../../../store/actions/products';
import FileInput from '@/components/Inputs/FileInput';
import SkeletonCourseVideoFile from '../../../Components/skeletons/SkeletonCourseVideoFile.vue';
import vueDropzone from 'vue2-dropzone';
import { FETCH_VIDEOS } from '../../../../store/actions/video';
import { VueTabs, VTab } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import SeoView from '../Seo/SeoView.vue';
import { CREATE_SEO, UPDATE_SEO } from '../../../../store/actions/seo';

export default {
  name: 'ClassDetails',
  components: {
    SeoView,
    VueTabs,
    VTab,
    vueDropzone,
    SkeletonCourseVideoFile,
    quillEditor,
    Multiselect,
    LoadingPanel,
    FileInput,
  },
  data() {
    return {
      isLoading: true,
      isUpdateClass: false,
      isLoadingInstructors: true,
      isLoadingCategories: true,
      isLoadingProducts: true,
      isLoadingClassFiles: true,
      seoClass: undefined,
      isSeoLoading: false,
      poster: undefined,
      isLoadingVideo: true,
      editorOption: {
        placeholder: 'Inserta un texto aquí...',
      },
      formData: CLASS_DEFAULT_VALUES,
      states: [
        {
          status: 'DRAFT',
          name: 'Editando',
        },
        {
          status: 'PUBLISH',
          name: 'Publicado',
        },
      ],
      classFileOptions: {
        url: `${config.data.apiUrl}/admin/class/file/create?class_id=${this.$route.params.id}`,
        thumbnailWidth: 150,
        maxFilesize: 4000, // MB
        timeout: 9999999, // Miliseconds
        addRemoveLinks: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getClass',
      'getVideos',
      'getInstructors',
      'getCategories',
      'getProducts',
      'getClassFiles',
    ]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  watch: {
    getClass: function (newValue) {
      this.formData = newValue
        ? {
            ...newValue,
            poster: newValue.poster
              ? newValue.poster
              : {
                  url: '',
                },
          }
        : CLASS_DEFAULT_VALUES;

      if (newValue && newValue.seo) {
        this.seoClass = newValue.seo;
      }
    },
  },
  mounted() {
    this.fetchClass();
    this.fetchInstructors();
    this.fetchCategories();
    this.fetchProducts();
    this.fetchClassFiles();
    this.fetchVideos();
  },
  methods: {
    createSeo(data) {
      this.isSeoLoading = true;
      this.$store
        .dispatch(CREATE_SEO, {
          seo: {
            ...data,
          },
          entity: {
            relation: 'class',
            relation_id: this.$route.params.id,
          },
        })
        .then(() => {
          this.isSeoLoading = false;
          this.fetchClass();
          this.notify(
            'success',
            'Se ha actualizado la información SEO de la clase',
          );
          ``;
        })
        .catch(() => {
          this.isSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO de la clase',
          );
        });
    },

    updateSeo(data) {
      this.isSeoLoading = true;
      this.$store
        .dispatch(UPDATE_SEO, {
          ...data,
        })
        .then(() => {
          this.isSeoLoading = false;
          this.fetchClass();
          this.notify(
            'success',
            'Se ha actualizado la información SEO de la clase',
          );
        })
        .catch(() => {
          this.isSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO de la clase',
          );
        });
    },
    fetchVideos(filter = { search: '' }) {
      this.$store.dispatch(FETCH_VIDEOS, filter).then(() => {
        this.isLoadingVideo = false;
      });
    },
    fetchClassFiles() {
      this.isLoadingClassFiles = true;
      this.$store
        .dispatch(FETCH_CLASS_FILES, {
          class_id: this.$route.params.id,
        })
        .then(() => {
          this.isLoadingClassFiles = false;
        })
        .catch(() => (this.isLoadingClassFiles = false));
    },
    deleteClassFile(classId) {
      this.$store
        .dispatch(DELETE_CLASS_FILE, classId)
        .then(() => {
          this.notify('success', 'Se ha eliminado el archivo correctamente!');
          this.fetchClassFiles();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas al eliminar el archivo');
        });
    },
    changePoster(files) {
      this.formData.poster.url = URL.createObjectURL(files[0]);
      this.poster = files[0];

      const formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('file', files[0]);
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_CLASS_POSTER, formData)
        .then(() => {
          this.fetchClass();
          this.notify('success', 'Se ha actualizado la imagen correctamente');
        })
        .catch(() => {
          this.notify('success', 'Tenemos problemas para actualizar la imagen');
          this.isLoading = false;
        });
    },
    searchProduct(searchVideoQuery) {
      this.isLoadingProducts = true;
      this.$store
        .dispatch(FETCH_PRODUCTS, {
          search: searchVideoQuery,
        })
        .then(() => {
          this.isLoadingProducts = false;
        });
    },
    fetchProducts() {
      this.$store.dispatch(FETCH_PRODUCTS).then(() => {
        this.isLoadingProducts = false;
      });
    },
    searchTrailer(searchTrailerQuery) {
      this.fetchVideos({ search: searchTrailerQuery });
    },
    searchInstructor(searchInstructorQuery) {
      this.fetchInstructors({ search: searchInstructorQuery });
    },

    searchCategory(searchCategoryQuery) {
      this.fetchCategories({ search: searchCategoryQuery });
    },
    fetchClass() {
      this.$store
        .dispatch(FETCH_CLASS, this.$route.params.id)
        .then(() => (this.isLoading = false));
    },
    fetchInstructors(filter = { search: '' }) {
      this.isLoadingInstructors = true;
      this.$store.dispatch(FETCH_INSTRUCTORS, filter).then(() => {
        this.isLoadingInstructors = false;
      });
    },
    fetchCategories(filter = { search: '' }) {
      this.isLoadingCategories = true;
      this.$store.dispatch(FETCH_CATEGORIES, filter).then(() => {
        this.isLoadingCategories = false;
      });
    },
    refetchClass() {
      this.isLoading = true;
      this.fetchClass();
    },
    updateClass() {
      if (!this.formData.video) {
        this.notify(
          'danger',
          'Debe tener asignado un video antes de actualizar',
        );
        return;
      }
      this.isUpdateClass = true;

      this.$store
        .dispatch(UPDATE_CLASS, this.formData)
        .then(() => {
          this.notify('success', 'Se ha actualizado la clase con éxito');
          this.isUpdateClass = false;
          this.refetchClass();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar la clase'),
            (this.isUpdateClass = false);
        });
    },
    successUploadClassFile(file) {
      this.fetchClassFiles();
      this.$refs.filedropzone.removeFile(file);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style>
.select-status {
  min-height: 50px;
}

.details-select {
  top: 50%;
}

.multiselect--active .multiselect__select {
  -webkit-transform: none;
  transform: none;
}

.multiselect__select::before {
  top: 35%;
}

.multiselect__single {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  line-height: 30px;
  font-size: 16px;
}

.multiselect__placeholder {
  padding-top: 0px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  font-size: 16px;
}

.multiselect__tag {
  margin-top: 10px;
}

.multiselect__tags {
  padding: 0 40px 0 13px !important;
  min-height: 50px;
}

.multiselect__input {
  padding: 0 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 30px;
}

.multiselect__select {
  height: 50px;
  width: 50px;
  margin-top: 0 !important;
  padding: 0 !important;
}

.preview-class-edit {
  text-align: -webkit-right;
}

.preview-class-edit a {
  color: #b7227e !important;
  text-decoration: none;
  align-content: right;
}

.vue-tabs .nav-tabs > li.active > a,
.vue-tabs .nav-tabs > li.active > a:hover,
.vue-tabs .nav-tabs > li.active > a:focus {
  background-color: #fff;
  border: none;
  cursor: default;
  border-bottom: 4px solid #000;
  color: #000;
}

.vue-tabs .nav-tabs > li {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.vue-tabs .nav-tabs > li > a {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid white;
}

.vue-tabs .nav > li > a:hover,
.vue-tabs .nav > li > a:focus {
  background-color: transparent;
  border-bottom: 4px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
}

.vue-tabs .nav-tabs {
  border-bottom: none;
}

.container-styles {
  background: white;
  border-radius: 4px;
}

.float-button {
  float: right;
  padding: 26px 15px 0;

  a {
    i {
      font-size: 26px;
      color: #b7227e;
    }
  }
}
</style>
